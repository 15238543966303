import { render, staticRenderFns } from "./viewHospitalPage.vue?vue&type=template&id=bea78334&scoped=true&"
import script from "./viewHospitalPage.vue?vue&type=script&lang=js&"
export * from "./viewHospitalPage.vue?vue&type=script&lang=js&"
import style0 from "./viewHospitalPage.vue?vue&type=style&index=0&id=bea78334&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bea78334",
  null
  
)

export default component.exports